import store from '@/store'

export default [
  {
    path: '/profiles',
    name: 'profiles',
    component: () => import('@/views/Profiles.vue'),
    meta: {
      pageTitle: 'Profiles',
      breadcrumb: [
        {
          text: 'Profiles',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/business-details',
    name: 'business-details',
    beforeEnter() {
      const userHasGroupID = store.getters['user/getSelectedGroupMember'].user_has_group.id
      window.open(
        `${process.env.VUE_APP_SIMI_CONNECT_BASE_URL}group-members?groupId=${userHasGroupID}`,
        '_blank',
      )
    },
  },
  {
    path: '/billing-details',
    name: 'billing-details',
    beforeEnter() {
      const userHasGroupID = store.getters['user/getSelectedGroupMember'].user_has_group.id
      window.open(
        `${process.env.VUE_APP_SIMI_CONNECT_BASE_URL}billing-details?groupId=${userHasGroupID}`,
        '_blank',
      )
    },
  },
]
