import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    businessTrialList: {},
    NonConfiguredList: {},
    UserHasGroup: {},
  },
  mutations: {
    SET_BUSINESS_TRIAL_LIST(state, payload) {
      state.businessTrialList = { ...payload }
    },
    SET_NON_CONFIGURED_SCREEN_LIST(state, payload) {
      state.NonConfiguredList = { ...payload }
    },
    SET_EXPIRY_Date_SET() {
    },
    SET_USER_HAS_GROUP(state, payload) {
      state.UserHasGroup = { ...payload }
    },
  },
  actions: {
    async getBusinessTrialList({ commit }, { offset, limit, query }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/user-has-group/get-trail-groups?offset=${
          offset - 1
        }&limit=${limit}&search_query=${query}`)
        if (response.data.statusCode === 200) {
          const { data } = response
          commit('SET_BUSINESS_TRIAL_LIST', data.data)
        }
        return true
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async getNonConfiguredScreenList({ commit }, { offset, limit, query }) {
      try {
        const response = await Vue.prototype.$axios.get(`/admin/device/no-ssp-configure?offset=${
          offset - 1
        }&limit=${limit}&search_query=${query}`)
        if (response.data.statusCode === 200) {
          const { data } = response
          commit('SET_NON_CONFIGURED_SCREEN_LIST', data.data)
        }
        return true
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    async updateBusinessExpiryDate({ commit }, { userHasGroupId, extend_trail_end_date }) {
      try {
        const response = await Vue.prototype.$axios.put('/admin/package/update-trail-package', { extend_trail_end_date }, {
          headers: {
            userHasGroupId,
          },
        })
        if (response.data.statusCode === 200) {
          commit('SET_EXPIRY_Date_SET')
        }
        return true
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getUserGroups({ commit }, { userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.get(`admin/user-has-group/get-user-group-details/${userHasGroupId}`)
        if (response.data.statusCode === 200) {
          commit('SET_USER_HAS_GROUP', response.data.data)
        }
        return true
      } catch (e) {
        console.log(e)
        throw e
      }
    },
  },
  getters: {
    getBusinessTrialList: state => state.businessTrialList,
    getNonConfiguredList: state => state.NonConfiguredList,
    getUserHasGroup: state => state.UserHasGroup,
  },
}
