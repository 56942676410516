export default [
  {
    path: '/pmpAdsList',
    name: 'pmpAdsList',
    component: () => import('@/views/admin/pmpAdsList/index.vue'),
    meta: {
      pageTitle: 'External Ads List',
      breadcrumb: [
        {
          text: 'External Ads List',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/pmpAdsList/view-ads/:id',
    name: 'user-pmp-ads-view',
    component: () => import('@/views/pmpAds/ViewPmpAd.vue'),
    meta: {
      pageTitle: 'View External Ad',
      breadcrumb: [
        {
          isDynamic: true,
          text: 'External Ads List',
          to: '/pmpAdsList',
        },
        {
          text: 'View External Ad',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
  {
    path: '/admin/pmpAdsList/:id/analytics',
    name: 'pmp-ads-scheduler-analytics',
    component: () => import('@/views/pmpAds/PmpAdSchedulerAnalytics.vue'),
    meta: {
      navActiveLink: 'pmp-ads-scheduler-analytics',
      pageTitle: 'Scheduler',
      breadcrumb: [
        {
          text: 'External Ads List',
          to: '/pmpAdsList',
        },
        {
          text: 'Scheduler',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
